export const AcceptChallenge = `
  mutation acceptChallenge($challengeId: String!, $startDate: AWSTimestamp!) {
    acceptChallenge(challengeId: $challengeId, startDate: $startDate)
  }
`

export const StopChallenge = `
  mutation leaveChallenge($challengeId: String!) {
    leaveChallenge(challengeId: $challengeId)
  }
`

export const SwitchChallenge = `
  mutation switchChallenge(
    $newChallengeId: String!
    $startDate: AWSTimestamp!
  ) {
    switchChallenge(newChallengeId: $newChallengeId, startDate: $startDate)
  }
`

// Need string only to pass to publicApi
export const UnsubscribeChallengeEmails = `
  mutation unsubscribeChallengeEmails($unsubscribeToken: String!) {
    unsubscribeChallengeEmails(unsubscribeToken: $unsubscribeToken)
  }
`
